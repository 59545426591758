@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "PJ-light";
    src: url("../src/assets/fonts/Plus_Jakarta_Sans (1)/static/PlusJakartaSans-Light.ttf");
  }

  @font-face {
    font-family: "PJ-medium";
    src: url("../src/assets/fonts/Plus_Jakarta_Sans (1)/static/PlusJakartaSans-Medium.ttf");
  }

  @font-face {
    font-family: "PJ-regular";
    src: url("../src/assets/fonts/Plus_Jakarta_Sans (1)/static/PlusJakartaSans-Regular.ttf");
  }

  @font-face {
    font-family: "PJ-semibold";
    src: url("../src/assets/fonts/Plus_Jakarta_Sans (1)/static/PlusJakartaSans-SemiBold.ttf");
  }

  @font-face {
    font-family: "PJ-bold";
    src: url("../src/assets/fonts/Plus_Jakarta_Sans (1)/static/PlusJakartaSans-Bold.ttf");
  }

  @font-face {
    font-family: "inter";
    src: url("../src/assets/fonts/Inter/static/Inter-Regular.ttf");
  }

  @font-face {
    font-family: "inter-semi";
    src: url("../src/assets/fonts/Inter/static/Inter-SemiBold.ttf");
  }

  @font-face {
    font-family: "inter-bold";
    src: url("../src/assets/fonts/Inter/static/Inter-Bold.ttf");
  }

  @font-face {
    font-family: "inter-medium";
    src: url("../src/assets/fonts/Inter/static/Inter-Medium.ttf");
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: "inter"; */
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PJ-Regular" !important;
} */

html {
  font-size: 14px;
}

/* h1,
h2 {
  font-family: S-black;
}

h3,
h4 {
  font-family: S-extrabold;
}

h5 {
  font-family: S-bold;
}

h6 {
  font-family: S-medium;
} */

/* p,
a,
small {
  font-family: S-regular;
} */
::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #e8e8e8; /* color of the thumb */
  border-radius: 6px; /* roundness of the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* color of the track */
}

.text-color-light {
  color: #aaacad;
}

.btn-green {
  background: #6261c4;
  font-family: S-medium;
  color: #fff;
  font-size: 14px;
}

.btn-green:hover {
  background: #6261c4;
  font-family: S-medium;
  color: #fff;
  font-size: 14px;
}

.btn-green-outline {
  background: #fff;
  border: 1px solid #6261c4;
  font-family: S-medium;
  color: #6261c4;
  font-size: 16px;
}

.btn-white-outline {
  border: 1px solid #ffffff;
  font-family: S-medium;
  color: #fff;
  font-size: 16px;
}

.btn-black {
  background: #000;
  font-family: S-medium;
  color: #fff;
  font-size: 16px;
}

.btn-close-convo {
  background: transparent;
  border: none;
  font: 13px;
  color: #d9a120;
}

.btn-deactivate {
  background: transparent;
  border: none;
  font: 13px;
  color: #d92d20;
}

.btn-back {
  color: #fff;
  font: 15px S-medium;
  display: flex;
  align-items: center;
  background: #6261c4;
}

.btn-view {
  color: #3d4249;
  font: 13px;
  display: flex;
  align-items: center;
}

.link {
  text-decoration: none;
  list-style-type: none;
  color: #3d4249;
  cursor: pointer;
  font-size: 14px;
}

.link-underline {
  position: relative;
}

.link-underline:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #3d4249;
  left: 1px;
  bottom: -1px;
}

.success-tag,
.failed-tag,
.pending-tag {
  padding: 8px;
  border-radius: 4px;
  font-family: PJ-regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  border: 2px solid transparent;
}

.success-tag {
  border-color: #b0ffb0;
  color: #073500;
}

.failed-tag {
  border-color: #ffb0b0;
  color: #410000;
}

.pending-tag {
  border-color: #fef0c7;
  color: #dc6803;
}

.two-line-ellipsis {
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.one-line-ellipsis {
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* loader area */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 38px;
  height: 38px;
  margin: auto;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #333 transparent #333 transparent;
  animation: lds-dual-ring 0.5s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* loader area ends here */

.pages {
  min-height: 100vh;
  /* background-color: #fcfcfc; */
}

/* .pages::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background: #F2FCF0;
  top: 15em;
  z-index: -1;
} */
.pages .main-left-side {
  width: 12%;
  max-width: 12%;
}

.pages .main-right-side {
  width: 94.5%;
  max-width: 94.5%;
}

.card {
  border-radius: 10px;
  /* animation-duration: 0.5s;
  animation-name: slidein; */
}

label {
  margin-top: 25px;
  /* font: 15px s-Regular; */
  margin-bottom: 5px;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="date"],
input[type="month"],
input[type="tel"],
input[type="url"],
textarea,
select {
  min-height: 44px;

  border-radius: 8px;
}

input[type="search"] {
  min-height: 40px;
  /* font: 12px s-Regular; */
  background-color: #e8e8e8;
  border-radius: 25px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
textarea:focus,
select:focus {
  border-color: #6261c4;
  box-shadow: none;
  outline: none;
}

.file-input-area {
  /* background-color: #ced8c0; */
  /* background-color: #eeeeee; */
  position: relative;
  /* padding-bottom: 4px; */
}

.file-input-area div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.file-input-area input {
  color: transparent;
}

.file-input-area input::-webkit-file-upload-button {
  visibility: hidden;
}

.file-input-area input::before {
  content: "Click here to select file to upload";
  color: #081630;
  display: inline-block;
  background: transparent;
  margin: 0 auto;
  padding-left: 22%;
  padding-top: 5px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font: 12px S-bold;
}

.file-input-area small {
  color: #081630;
  font: 12px s-Regular;
}

.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 26px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  right: 0;
  background-color: #d5dbd4;
  transition: 1s ease;
  border-radius: 20px;
}

.switch .slider:after {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 5px;
  bottom: 2px;
  background-color: #6261c4;
  transition: 0.4s;
  border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: #6261c4;
}

.switch input:checked + .slider:after {
  transform: translateX(20px);
  background-color: #fff;
}

.main-tab-card {
  padding: 15px 0px;
  border: none;
  padding: 15px 10px;
  background: #eeeeee;
  border: 1px solid rgb(216, 213, 213);
  border-radius: 3px;
}

.main-tabs .nav-tabs .nav-item.show .nav-link,
.main-tabs .nav-tabs .nav-link.active {
  color: #6261c4;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #6261c4;
}

.main-tabs .nav-tabs .nav-link:hover {
  color: #6261c4;
  border: none;
  border-bottom: 1px solid #6261c4;
}

.main-tabs .nav-tabs .nav-link {
  color: #aaacad;
  font-size: 14px;
  font-family: s-medium;
  cursor: pointer;
}

.main-tabs .nav-tabs {
  padding: 0px;
  margin: 0px;
  background-color: rgb(254, 254, 254);
  margin-bottom: 20px;
  box-shadow: 5px 10px 20px #d7d7d7;
}

.main-tab-card.verif-tab.nav.nav-tabs {
  justify-content: center;
}

.sub-tab-sidebar .sub-tabs-navigation ul {
  list-style-type: none;
  padding: 5%;
}

.sub-tab-sidebar .sub-tabs-navigation ul li {
  text-decoration: none;
  list-style-type: none;
  margin: 2px 0px;
  cursor: pointer;
}

.sub-tab-sidebar .sub-tabs-navigation ul li:hover span,
.sub-tab-sidebar .sub-tabs-navigation ul li.active span {
  /* color: #6261c4; */
  color: #fff;
  background-color: #6261c4;
  color: #fff;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 5px 5%;
}

.sub-tab-sidebar .sub-tabs-navigation ul li span {
  color: #162f43;
  display: flex;
  align-items: center;
  font-size: 17px;
  padding-left: 5%;
  margin: 10px 0px;
  cursor: pointer;
}

.sub-tab-sidebar .sub-tabs-navigation ul li i {
  margin: 0px 10px;
  font-size: 30px;
}

.sub-tab-sidebar .sub-tabs-navigation ul li p {
  margin: 0px;
  font: 17px s-Regular;
}

.chat-tab {
  display: flex;
  /* border-bottom: 1px solid #aaacad; */
  /* background-color: #e8e8e8; */
  border-radius: 20px;
  text-align: center;
  /* width: 100%; */
}

.chat-tab h5 {
  /* padding-top: 10px;
  width: 60px; 
  height: 35px; */
  /* font: 12px s-Regular; */
  /* color: #aaacad; */
  cursor: pointer;
  margin: 0px;
}

.chat-tab h5:hover,
.chat-tab h5.active {
  color: black;
  /* border-bottom: 2px solid #6261c4; */
  background-color: #f1e4ff;
  border-radius: 20px;
}

.chat-head {
  /*position: fixed;
  z-index: 999;
  background: white;*/
}

.table-header {
  border-bottom: 1px solid #36356c;
  color: #333;
}

/* .table tr th {
  font-size: 15px;
  font-family: PJ-regular;
  border: 1px solid black;
  border-radius: 8px;
  background-color: #c0b9b9;
} */
.table > thead > th:last-child {
  font-size: 15px;
  font-family: PJ-regular;
  border: 1px solid black;
  border-radius: 8px;
  background-color: blue;
  box-shadow: 2px 2px 2px 2px #000;
}
/* .table th {
  color: #101010;
font-family: PJ-regular;
/* font-size: 16px; */
/* font-style: normal;
font-weight: 400;
line-height: 20px; 125% */
/* letter-spacing: -1px;
border-radius: 8px;
background-color: blue;
border: 1px solid; */
/* }  */

.table tbody td {
  /* font-family: s-Regular; */
  font-size: 14px;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  margin: 50px 0px;
  border-bottom: 0.4px solid
    var(--Foundation-Secondary-color-secondary-color-50, #e7e6e8);
  /* background-color: blueviolet; */
}
.table tbody td .table-ellipse,
.table tbody th .table-ellipse {
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-width: 300px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pagination {
  z-index: 10;
  font: 13px S-bold;
  justify-content: flex-end;
}

.pagination-item {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 5px 10px;
  color: #01313f;
}

.pagination-item.active {
  background-color: #4a4994;
  color: #fff;
  border-radius: 5px;
}

.pagination-wrapper {
  display: flex;
  /* background-color: #fffae6; */
  list-style-type: none;
  padding: 10px 5px;
  justify-content: end;
  align-items: center;
}

.pagination-wrapper li {
  margin-right: 15px;
  font: 13px S-bold;
}

.pagination-wrapper li a {
  text-decoration: none;
}

.pagination-wrapper .disabled {
  opacity: 0.3;
}

.pagination-wrapper .active {
  background-color: #4a4994;
  color: #ffffff;
  padding: 7px 10px;
}

.main-modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  padding: 50px 10px;
  overflow-y: scroll;
}

.main-modal .close-modal {
  width: 23px;
  height: 23px;
  border-radius: 5px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 32px;
  right: 20px;
  cursor: pointer;
}

.main-modal-content {
  /* background-color: #fff; */
  border-radius: 10px;
  padding: 20px;
  position: relative;
  animation: 0.5s fadein ease-in-out;
}

.sidebar-sm .sidebar-area {
  /* background-color: #ebefe7; */
  /* background-color: #6261c4; */
  color: white;
  width: 280px;
  /* min-width: 10.33%; */
  display: flex;
}

.sidebar-lg .sidebar-area {
  /* background-color: #ebefe7; */
  /* background-color: #6261c4; */
  min-width: 25.66%;
  display: none;
}

.sidebar-area {
  height: 100vh;
  position: fixed;
  background-color: #010101;
  overflow-y: scroll;
  border-right: 1px solid #e6e6e6;
  justify-content: space-between;
  flex-direction: column;
  width: 200px;
  /* padding: 25px; */
}

.sidebar-area::-webkit-scrollbar {
  display: none;
}

/* .sidebar-sm .sidebar-area .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  padding-left: 10%;
}
.sidebar-lg .sidebar-area .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding-left: 10%;
}
.sidebar-area .header .logo {
  padding: 0;
  margin: 0;
  color: #ffffff;
}
.sidebar-area .header span i {
  font-size: 20px;
  font-weight: 900;
} */
.sidebar-area .navigation {
  padding: 5% 0%;
}

/* .sidebar-area .navigation h6 {
  color: #bcbcbc;
  padding-left: 13%;
  font-size: 12px;
} */

.TeamMember h1 {
  /* font-family: PJ-regular; */
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.96px;
  color: #101010;
}
.TeamMember p {
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.56px;
}
.searchIcon {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 13px;
  margin-left: 22px;
  display: unset;
}
.searchTeam {
  position: relative;
}
.searchTeam input {
  /* display: flex; */
  width: 300px;
  min-height: 10px;
  border: 1px solid #d9d9d9;
  padding: 13px 98px 12px 48px;
  align-items: center;
  border-radius: 12px;
  background-color: #fff;
}
/* .iconUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-items: center;
} */
.uploadIcon {
  height: 20px;
  width: 20px;
}
.uploadIcon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  background-color: #010101;
  width: 48px;
  height: 48px;
}
.teams {
  padding-top: 40px;
}
.teamButton {
  display: flex;
  align-items: flex-end;
  gap: 22px;
  justify-content: flex-end;
  padding: 20px 0;
}

.button2 {
  background-color: #6261c4;
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.72px;
  display: flex;
  /* width: 223px; */
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  gap: 12px;
}
.Broadcastbutton {
  background-color: #6261c4;
  color: whitesmoke;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.72px;
  width: 150px;
  height: 45px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  margin-bottom: 20px;
}

.button2 img {
  /* color: #000; */
  /* background-color: #000; */
}

/* .firstNav {
  margin-bottom: 72px;
}
.firstNav p {
  padding-left: 8px;
}
.secondNav {
  margin-bottom: 72px;
}
.secondNav p {
  padding-left: 8px;
}
.thirdNav p {
  padding-left: 8px;
} */
.sideBars {
  font-family: pj-regular;
  width: fit-content;
  /* margin: 0 auto; */
}

.passwordText {
  cursor: pointer;
  color: #6261c4;
}
.teamTable {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 40px;
  margin-bottom: 32px;
  padding-left: 44px;
}
.broadcastTable {
  position: relative;
  /* margin-top: 2px; */
  margin-bottom: 32px;
  padding-left: 48px;
}
.broadcastTableClass {
  width: 100%;
}
.tableTitle {
  width: 18%;
}

.cards {
  width: 400px;
  /* border: 1px solid #6261c4; */
  border-radius: 8px;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.15);
}
.extraP {
  font-family: pj-regular;
  font-weight: 400;
  font-size: 18px;
}
.checkoutCardButton {
  padding: 8px 10px;
  border-radius: 4px;
  background-color: #6261c4;
  border: none;
  color: white;
  box-shadow: 0px 2px 2px 0px #6261c4;
}
.counters {
  padding: 10px 10px;
  border-radius: 8px;
  box-shadow: 2px 2px 2px 2px whitesmoke;
}
.createBroadcast {
  margin-top: 109px;
  gap: 32px;
  font-family: pj-regular;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.96px;
}
.broadcast {
  display: flex;
  justify-content: center;
  border: 2px #f1f1f1 solid;
  width: 800px;
  border-radius: 10px;
  margin-bottom: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.broadcastTitle {
  margin-top: 58px;
  gap: 32px;
  font-family: Pj-regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.72px;
}
.broadcastTitle input {
  width: 458px;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  background-color: #fff;
  padding-left: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease-in-out;
  /* box-shadow: 22px 18px 52px 16px rgba(217, 217, 217, 0.04); */
}
.broadcast:hover {
  outline: none;
  border-color: #f1f1f1;
  box-shadow: 0 0 8px #f1f1f1;
}
.reactQuill,
.reactQuillPreview {
  width: 100%;
  overflow: hidden;
  word-wrap: break-word;
}

.reactQuill .ql-container {
  border: none;
}

.reactQuill .ql-editor {
  min-height: 300px; /* Adjust based on your needs */
}

.previewImage {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.text-wrapper {
  overflow: hidden;
  word-wrap: break-word;
}

.inAppBroadcast {
  border: 1px solid #e7e6e8;
  border-radius: 10px;
}

.reactQuill {
  width: 458px;
  height: 358px;
  border-radius: 8px;
}
.reactQuill .ql-editor {
  color: #424242;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.64px;
  /* border: 1px solid #f1f1f1; */
  border-radius: 14px;
  box-shadow: 0px 2px 2px 0px rgba(217, 217, 217, 0.04);
}
.ql-toolbar.ql-snow {
  border: 2px solid rgba(217, 217, 217, 0.6);
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
  font-size: x-large;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  box-shadow: 0px 2px 2px 0px rgba(217, 217, 217, 0.04);
  background-color: #fbfbfb;
}

.broadcastQuill {
  margin-top: 72px;
  gap: 56px;
  font-family: Pj-regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.72px;
}
.category {
  margin-top: 112px;
  gap: 70px;
  font-family: Pj-regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.72px;
}

.optional {
  color: gray;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.72px;
}
.rounded-circle {
  border-radius: 50%;
}

.bg-gray {
  background-color: #808080; /* Use the desired shade of gray */
}
.uploadBroadcast {
  margin-top: 72px;
  gap: 24px;
  justify-items: center;
  font-family: Pj-regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.72px;
}
.uploadIconContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  width: 458px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(217, 217, 217, 0.12);
  padding: 15px 75px;
  border-radius: 10px;
}

.uploadIconContainer p {
  color: #8b8b8b;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.64px;
  margin-top: 17px;
}

.selectCategory {
  position: relative;
  width: 460px;
  height: 40px;
  color: #8b8b8b;
  border: none;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.64px;
  border-radius: 8px;
  border: 1px solid #b5b5b5;
  transition: border-color 0.3s ease-in-out;
  padding: 4px;
}

.selectCategory select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: #fff;
  appearance: none;
}

.selectCategory::after {
  content: "\25BC";
  font-size: 12px;
  color: #333;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the icon doesn't interfere with clicks on the select */
}

.selectCategory select:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.selectCategory:hover select {
  border-color: purple;
}
.createBroadcastButton {
  border-radius: 8px;
  border: 1px solid #6261c4;
  background-color: #6261c4;
  width: 163px;
  height: 48px;
  padding: 14px 18px;
  color: whitesmoke;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.64px;
}

.buttonEnd {
  margin-top: 36px;
  justify-content: end;
  display: flex;
  margin-bottom: 100px;
}

td.dropdown-trigger {
  cursor: pointer; /* Add this to indicate it's clickable */
}

.dropdown-content {
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: 0;
  padding: 0;
  font-family: PJ-Regular;
  z-index: 1000;
  box-shadow: 0px 4px 56px 0px rgba(217, 217, 217, 0.12);
  border-radius: 12px;
  /* padding: 2px 30px; */
  background-color: #fdfdfd;
  border: 1px solid #f1f1f1;
  gap: 10px;
}
.teamAvatar {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #9f6ecc;
  box-shadow: 0px 0px 2px 0px #424242;
}
.teamInitials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  color: white;
  font-family: Inter;
  border-radius: 100%;
  box-shadow: 0px 12px 40px -8px rgba(217, 217, 217, 0.25);
}
.editDrop {
  color: black;
  padding: 8px;
  margin: 0;
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-family: PJ-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.051px;
}

.deleteDrop {
  color: red;
  display: flex;
  gap: 10px;
  padding: 8px;
  margin: 0;
  cursor: pointer;
  color: #ff5959;
  font-family: PJ-regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.051px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */
  z-index: 999; /* Ensure it's above other elements except the modal */
}

.delete-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 24px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.delete-modal-content {
}

.delete-modal-buttons {
  display: flex;
  font-family: inter;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
}
.deleteButton2 {
  background-color: #ff5959;
  color: white;
}
.deleteButton1 {
  border-radius: 8px;
  border: 2px solid #f1f1f1;
  margin-right: 4px;
}

.deleteBin {
  /* color: red; */
  width: 40px;
  height: 40px;
  background-color: #ffd8d8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-modal button {
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  width: 186px;
}

.AddButton {
  border-radius: 8px;
  border: 1px solid #6261c4;
  background-color: #6261c4;
  display: flex;
  /* width: 186px; */
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: whitesmoke;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.64px;
}
.cardHeader {
  border-top: 0.4px solid rgba(176, 176, 176, 0.4);
}
.tableName {
  color: black;
}

.tableTitle h1 {
  /* font-family: PJ-regular; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;
  color: #101010;
}
.tableTitle p {
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.56px;
}
.tableClass {
  width: 70%;
  /* border-top: 1px solid #B0B0B0A0; */
}
.button1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.72px;
  display: flex;
  border-radius: 4px;
  width: 103px;
  padding: 16px 20px;
  justify-content: center;
  height: 54px;
  margin-top: 80px;
  padding: 8px 12px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 12px;
  align-items: center;
  border: 0.4px solid rgba(187, 187, 187, 0.4);
}
.topNav {
  display: flex;
  padding-left: 60px;
  padding-right: 72px;
  justify-content: space-between;
}
.topNavs {
  padding-top: 40px;
  display: flex;
  padding-left: 60px;
  padding-right: 72px;
  justify-content: space-between;
}
.topNav2 {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.sidebar-sm .sidebar-area .navigation ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-lg .sidebar-area .navigation ul {
  list-style-type: none;
  padding: 5%;
}

.sidebar-sm .sidebar-area .navigation ul li {
  text-decoration: none;
  list-style-type: none;
  color: #c7c7cd;
  /* padding: 0px 6px; */
  margin: 15px auto;
  /* width: 80%; */
}

.sidebar-lg .sidebar-area .navigation ul li {
  text-decoration: none;
  list-style-type: none;
  color: #c7c7cd;
  padding: 3px 0px;
  margin: 2px 0px;
}

.sidebar-sm .sidebar-area .navigation ul li:hover,
.sidebar-sm .navigation ul li.active {
  /* background-color: #6261c4; */
  color: white;
  /* width: 80%; */
  border: none;
  border-radius: 0px;
  cursor: pointer;
  /* border-radius:10px; */
  /* border-right: 3px solid white; */
}

.sidebar-lg .sidebar-area .navigation ul li:hover,
.sidebar-lg .navigation ul li.active {
  background-color: #3d4249;
  color: #fff;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.navigation ul li.active div,
.navigation ul li:hover div {
  background: linear-gradient(
    93deg,
    #726282 6.39%,
    rgba(180, 156, 204, 0) 169.8%
  );
  color: whitesmoke;
  border-radius: 8px;
  width: 100%;
  /* padding: 10px 5px; */
}
.menu-list-bg {
  background: linear-gradient(#8430d8 100%, #7b4bac 100%);
}

.sidebar-area .navigation ul li:hover div {
  /* --------- */
}

.sidebar-sm .sidebar-area .navigation ul li div {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;
  margin: 7px 0px;
  color: white;
  padding: 8px 12px;
}

.sidebar-lg .sidebar-area .navigation ul li div {
  color: #3d4249;
  display: flex;
  align-items: center;

  /* font-size: 10px; */
  /* padding-left: 5%; */
  /* margin: 7px 0px; */
}

.sidebar-area .navigation ul .link {
  text-decoration: none;
  list-style-type: none;
  color: white;
}

.sidebar-area .navigation ul li i {
  margin: 0px 10px;
  font-size: 23px;
  color: white;
}

.sidebar-area .navigation ul li h6 {
  margin: 0px;
  padding-top: 5px;
  font: 14px s-Regular;
}

.sidebar-area .navigation ul li img {
  margin: 0px 10px;
  width: 20px;
  color: white;
}

.paginate {
  /* 
  border-radius: 10px;
  color: #aaacad;
  height: 35px;
  bottom: 0;
  width: 19%;
  text-align: center;
  padding: 4px;
  font-size: 12px;
  font-weight: 900; */
}

.paginate li a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 0 5px;
}

.paginate li:first-child a,
.paginate li:last-child a {
  border: none;
  text-decoration: none;
  color: #010101 !important;
}

.pagination_active a {
  background-color: #ffe4fe;
  border-color: #ffe4fe !important;
}
.paginates {
  /* border-radius: 10px; */
  /* color: #aaacad; */
  /* height: 35px; */
  /* bottom: 0; */
  /* width: 19%; */
  /* text-align: center; */
  /* padding: 4px; */
  /* font-size: 12px; */
  /* font-weight: 900; */
}

.paginates li a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  color: #8c8c8c;
  font-family: inter-medium;
  text-size: 14px;
  border-color: #d9d9d9 !important;
  margin: 0 5px;
}

.paginates li:first-child a,
.paginates li:last-child a {
  border: 1px solid #ccc;
  text-decoration: none;
  color: #8c8c8c !important;
  width: 36px;
  height: 36px;
  border-radius: 6px;
}

.paginations_active a {
  color: #010101 !important;
  border-color: #d9d9d9 !important;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-family: inter-medium;
}

.profile {
  position: relative;
  display: flex;
  align-items: center;
  font-family: s-Regular;
  cursor: pointer;
}

.clist-parent {
  max-height: calc(100vh - 315px); /* Very important */
}

.messagePagination {
  /* position: ; */
  color: white;
  background: black;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  padding: 10px;
}

.messagesView {
  height: calc(100vh - 315px + 81px); /* very important */
}

.more-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: s-Regular;
  cursor: pointer;
  width: 100%;
}

.profile .avatar {
  width: 44px;
  height: 44px;
  min-width: 44px;
  border-radius: 50%;
  /* background-color: #d0d5dd; */
  overflow: hidden;
  /* border: 2px solid #6261c4; */
  border: 1px solid #333;
  margin: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile .avatar img {
  border-radius: 50%;
  width: 70%;
  height: 29px;
  padding: 3px;
  object-fit: fill;
}

.profile h6 {
  padding: 0;
  margin: 0;
  font: 12px s-Regular;
  color: #6b778c;
}

.profile p {
  padding: 0;
  margin: 0;
  font: 15px S-medium;
}

.user-profile {
  position: absolute;
  /* top: 120%; */
  top: -235%;
  min-height: 100%;
  width: 200px;
  background: #fff;
  padding: 6px 20px 6px 10px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.more-options {
  position: absolute;
  top: 120%;
  min-height: 100%;
  width: 240px;
  background: #fff;
  padding: 6px 20px 6px 10px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  right: 0%;
}

.user-profile div {
  display: flex;
  align-items: center;
}

.user-profile .pro {
  color: #091e42;
  font-size: 16px;
  border-bottom: 1px solid #dcdfe5;
}

.user-profile .pro p {
  font-size: 15px;
  padding-left: 10px;
  color: #091e42;
  margin: 0;
}

.user-profile .logout {
  display: flex;
  font-size: 18px;
  color: #ff0000;
}

.user-profile .logout p {
  font-size: 15px;
  padding-left: 10px;
}

/* auth Area */
.auth-page-area {
  height: calc(100vh - 80px);
  /* background: url('./assets/refBg.png'), #f7fcfc; */
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
  /* background-size: 50% 100%, 100%; */
}

.auth-page-area .left-side {
  background-color: #4a4994;
  /* border-radius: 15px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  color: #fff;
  padding-bottom: 20px;
  position: relative;
}

.auth-page-area .left-side::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
  background: url("./assets/auth-icon.png") center center no-repeat;
  background-size: cover;
  opacity: 0.2;
}

.auth-page-area .card {
  background: #ffffff;
  border: 2px solid #e9f0fc;
  box-shadow: 0px 0px 52px 5px rgba(3, 68, 86, 0.25);
  /* border-radius: 8px; */
}

.auth-page-area .card p {
  font-size: 14px;
  color: #3d4249;
}

/* auth Area */

/* dashboard page */
.dash-counts-area .card i {
  color: #4a4994;
  font-size: 35px;
}

.dash-counts-area .card:hover {
  color: #fff;
  background-color: #6261c4;
}

.dash-counts-area .card:hover i {
  color: #fff;
}

/* dashboard page ends here */

/* profile page Area */
.profile-info-area .avatar {
  border-radius: 50%;
  color: #fff;
  background: #010101;
}

.profile-info-area .avatar img {
  border-radius: 50%;
  width: 50%;
  height: 70px;
  object-fit: fill;
}

.upload-img-btn {
  color: transparent;
  max-width: 170px;
}

.upload-img-btn::-webkit-file-upload-button {
  visibility: hidden;
}

.ellipsis-cell {
  max-width: 401px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis-title {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  padding-top: 0%;
  color: #101010;
}
.upload-img-btn::before {
  content: "Change Profile Picture";
  color: #6261c4;
  display: inline-block;
  border: 1px solid #6261c4;
  padding: 8px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font: 13px s-bold;
}

.upload-img-btn:hover::before {
  border-color: #081630;
}

.upload-img-btn:active {
  outline: 0;
}

.upload-img-btn:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.profileLink {
  font-family: pj-regular;
}

.HomeTitle {
  color: #101010;
  font-family: pj-regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.96px;
}
.searchHome {
  position: relative;
}

.searchHome input {
  /* display: flex; */
  min-height: 10px;
  width: 382px;
  min-height: 10px !important;
  border: 1px solid #d9d9d9;
  padding: 13px;
  padding-left: 50px;
  align-items: center;
  border-radius: 12px;
  background-color: #fff;
}
.upgrade {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background-color: #7c4bac;
  padding: 12px;
  border-top: 0.1px solid rgba(224, 224, 224, 0.4);
  /* position:absolute; */
}
.circularProgress {
  width: 39.611px;
  height: 39.611px;
  font-size: 14px;
}
.upgrade p {
  color: rgba(17, 17, 17, 0.8);
  font-family: pj-regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  letter-spacing: -0.4px;
}
.welcomeTitle {
  color: #101010;
  /* font-family: pj-regular; */
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.12px;
  margin-top: 80px;
  margin-bottom: 8px;
}
.welcomeMessage {
  color: rgba(17, 17, 17, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
}

/* .upgradeButton {
  display: flex;
  width: 125px;
  height: 40px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background-color: white;
  border: 2px solid #be95fc;
} */

.homeCard {
  /* width: 600px; */
  width: 100%;
  padding: 12px;
  margin-top: 32px;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  border-radius: 8px;
  border: 0.5px solid rgba(169, 169, 169, 0.4);
  background-color: #fff;
  box-shadow: 12px -3px 44px 0px rgba(50, 50, 51, 0.02),
    0px 4px 56px 0px rgba(50, 50, 50, 0.02);
}
.crumbs {
  color: var(--Default-Body-Color, #181b01);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;
  color: #000;
}

.homeCard button {
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid var(--Foundation-Primary-color-primary-color-100, #ecd8fe);
  color: #462b60;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.56px;
}
.firstHalf {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);

  /* gap: 30px; */
}
.cardImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47.273px;
  height: 47.273px;
  flex-shrink: 0;
  border-radius: 11.743px;
  background-color: #3f0f3f;
  box-shadow: 0px 0px 1.678px 0px rgba(70, 38, 70, 0.54),
    0px -6.71px 6.71px 0px #881e88 inset;
}
.cardhalf {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  padding: 18px 12px;
  align-items: center;
}
.cardhalf p {
  color: #111;
  text-align: start;
  font-family: pj-regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  letter-spacing: -0.72px;
}
.secondHalf {
  display: flex;
  flex-wrap: wrap;
  width: 728px;
  padding: 12px 12px 18px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
}
.secondHalf :first-child {
  color: #111;
  font-family: pj-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
  line-height: 150%; /* 24px */
}
.secondHalf :last-child {
  color: rgba(17, 17, 17, 0.7);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: -0.24px;
}

/* Profile setup Starts here */
.profileD {
  margin-bottom: 177px;
  margin-top: 90px;
}
.profileB {
  margin-bottom: 177px;
  margin-top: 90px;
}

.profileCard {
  display: flex;
  padding: 0px 32px;
  justify-content: space-between;
  align-items: center;
  gap: 500px;
  border-radius: 14px;
}

.profileCard1 {
  display: flex;
  /* padding: 0px 32px; */
  justify-content: space-between;
  align-items: center;
  /* gap: 500px; */
  border-radius: 14px;
}
.profileCard h4 {
  color: var(--Default-Body-Color, #181b01);
  font-family: pj-regular;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.96px;
  margin-top: 4px;
}
.profileCard p {
  color: var(--Neutrals-neutrals-8, #595959);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}
.profileCardButton {
  display: flex;
  width: 140px;
  height: 44px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #6b5c7a;
  background: #010101;
  color: #e5c9ff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
  text-decoration: none;
}

.businessInfo {
  display: flex;
  /* flex-direction: column; */
  margin-top: 64px;
  gap: 100px;
}

.businessInfo h6 {
  color: #101010;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: -0.27px;
}
.businessInfos h6 {
  color: #101010;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: -0.27px;
}
.businessInfo p {
  color: var(--Secondary-Color, #1c1f00);
  font-family: pj-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 21px */
}
.businessInfos p {
  color: var(--Secondary-Color, #1c1f00);
  font-family: pj-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 21px */
}
.profileInput {
  display: grid;
  grid-template-columns: 2fr 2fr;
  row-gap: 44px;
  column-gap: 32px;
}
.profileSubmitButton {
  display: flex;
  width: 140px;
  height: 44px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #6b5c7a;
  background-color: #010101;
  color: #e5c9ff;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
}
.infobusiness {
  color: #101010;

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  letter-spacing: -0.3px;
}
.infoProfile {
  color: #101010;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  letter-spacing: -0.3px;
}
.businessdata p {
  color: #505050;
  font-family: pj-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 52px;
}
.password-input {
  position: absolute;
}

.password-input input {
  /* padding-right: .5rem; */
}

.password-icons {
  position: absolute;
  top: 75%;
  transform: translateY(-50%);
  right: 0.75rem; /* Adjust as needed */
}

.password-icons svg {
  position: relative;
  cursor: pointer;
  right: 9;
}

.password-icons svg:first-child {
  margin-left: 0;
}

.infoBuss {
  color: var(--Secondary-Color, #1c1f00);
  font-family: pj-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 24px */
}
.businessdata h6 {
  color: var(--Secondary-secondary-700, #000e24);
  font-family: pj-regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.businessInput select {
  display: flex;
  width: 100%;
  height: 44px;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  background: #fefefe;
}
.profileInput input {
  display: flex;
  width: 100%;
  height: 30px;
  padding: 12px 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid #f1f1f1;
  background: #fefefe;
}
.businessInput input {
  display: flex;
  height: 44px;
  padding: 12px 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid #f1f1f1;
  background: #fefefe;
}
.profileInput label {
  color: rgba(89, 89, 89, 0.8);
  font-family: pj-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* .headerPic {
  width: 28px;
  height: 28px;
  border-radius: 12px;
  border: 1px solid #9f6ecc;
  background: url(<path-to-image>), lightgray 50% / cover no-repeat, #fff;
  box-shadow: 0px 12px 32px -8px #d9d9d9;
} */

/* profile page ends here */

/* Conversation view page area */
/* .chat-left-side {
  width: 20%;
  background-color: #fff;
  position: relative;
}

.chat-right-side {
  width: 80%;
} */

.chat-heads {
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 20%;
  height: 100vh;
  padding-bottom: 2rem;
}

.chat-heads .profile .avatar {
  margin-right: 10px;
  /* border: 1px solid #6261c4; */
}

.chat-heads .profile:hover,
.chat-heads .profile.active {
  /* background-color: #f1f1ff; */
  background-color: #fff;
  /* border-radius: 1px; */
  /* border-right: 3px solid #6261c4; */
  /* box-shadow: 5px 10px 10px #e3e3e3; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 3px 2px;
}
.conversation-view-area {
  /* height: calc(100vh - 295px); */
}
/* .conversation-view-area .convo{
  background: linear-gradient( #fbfffe 0%, #ffffff 100%);
} */
.conversation-view-area .card-header {
  height: 80px;
}

.conversation-view-area .convo {
  height: calc(100% - 80px);
}

.conversation-view-area .convo .card-body {
  /* overflow-y: scroll; */
}

.conversation-view-area .convo .card-body .question-area,
.conversation-view-area .convo .card-body .answer-area {
  width: 100%;
  max-width: 600px;
  padding: 10px 15px;
  width: fit-content;
}

.conversation-view-area .convo .card-body .question-area p,
.conversation-view-area .convo .card-body .answer-area p {
  padding: 0px;
  margin: 0px;
  font-size: 12px;
}

.conversation-view-area .convo .card-body .question-area {
  border-radius: 0 10px 10px 10px;
  background-color: #f3f3f3;
}

.conversation-view-area .convo .card-body .answer-area {
  margin-left: auto;
  border-radius: 10px 0 10px 10px;
  border: 1px solid #ccc;
}

.conversation-view-area .input-message {
  display: flex;
  align-items: center;
  border: 1px solid #c0c9d8;
  border-radius: 30px;
}

.conversation-view-area .input-message textarea {
  color: #181738;
}

/* .conversation-view-area .convo-settings .card-body{
  min-height: 100vh;
} */

.conversation-view-area .convo-details-area .convo-detail {
  display: flex;
  margin-bottom: 5px;
}

.conversation-view-area .convo-details-area .convo-detail i {
  min-width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #6261c4;
  border-radius: 50%;
  border: 1px solid #c0c9d8;
  margin-right: 10px;
  margin-top: 5px;
}

/* Conversation view ends here */

/* Subscription page starts here */
.sub-overview-area .active-card-label {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #eee;
  margin-top: 10px;
}

.sub-overview-area .active-card-label.active {
  border: 5px solid #6261c4;
}

.sub-overview-area .card-logo {
  width: 70px;
  border: 1px solid #eee;
  text-align: center;
  border-radius: 3px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-overview-area .card-logo img {
  width: 25px;
}

.sub-plans .plan-feature {
  min-height: 180px;
  padding-bottom: 20px;
}

.sub-plans .plan-feature i {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* background-color: #6261c4; */
  color: #000;
  font-size: 16px;
  margin-right: 10px;
}
/* Subscription page ends here */

/* accordion  */

.accordion {
  width: 100%;
  /* border:2px; */
}

.accordion-step {
  border: 0.4px solid #d9d9d999;
  margin-bottom: 10px;
  border-radius: 3px;
  background-color: white;
}
/* .messageCard {
  background-color: white;
  color: black;
  position: absolute;
  top: 380px;
  right: 40px;
  border-radius: 9px;
  box-shadow: 0px 0px 4px 0px rgb(193, 192, 192);
  display: flex;
  justify-content: center;
  padding: 9px 12px 12px 9px;
  width: 290px;
  display: flex;
  justify-content: space-between;
} */
.messageCard h6 {
  font-size: 10.5px;
  font-family: PJ-regular;
  font-weight: 500;
  line-height: 13.23px;
}
.messageCard p {
  font-size: 7.5px;
  font-family: Inter;
  font-weight: 400;
  line-height: 9.08px;
}

.inAppBroadcast :first-child {
  font-size: 7.5px;
  font-family: Inter;
  font-weight: 400;
  line-height: 9.08px;
}
.inAppBroadcast :last-child {
  font-size: 9px;
  font-family: PJ-regular;
  font-weight: 500;
  line-height: 13.5px;
}
.blackCard {
  margin-top: 42px;
  border-radius: 9px 9px 0px 0px;
  padding: 12px 8px 12px 8px;
}
.nameHeader h6 {
  font-weight: 500;
  font-size: 18px;
  font-family: PJ-regular;
  line-height: 22.68px;
}
.nameHeader p {
  font-weight: 400;
  font-size: 12px;
  font-family: Inter;
  line-height: 14.52px;
}

.accordion-header {
  padding: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
  font-family: PJ-regular;
  font-weight: 600;
  font-size: 18px;
  line-height: 30.24px;
  background-color: white;
  border: #a9a9a966;
}

.accordion-content {
  padding: 10px;
  display: none;
}
.accordion-content h6 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}
.accordionContent {
  display: flex;
  margin-top: 44px;
  gap: 100px;
}
.customBranding {
  background-color: #f5ecff;
  padding: 12px;
  font-size: 18px;
  font-family: Inter;
  font-weight: 500;
  line-height: 27px;
  border-radius: 8px;
  display: flex;
  gap: 12px;
}
.customBranding span {
  color: #592c83;
  font-weight: 600;
  text-decoration: underline;
  font-family: Inter;
}
.widgetLogo {
  font-weight: 300;
  font-family: PJ-regular;
  font-size: 14px;
  line-height: 21px;
}
.OnlineP {
  font-family: PJ-regular;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
}
.radioAlignment {
  display: flex;
  /* gap: 70px; */
  border: 4px black solid;
  border-radius: 12px;
  height: 127px;
  width: 202px;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.leftRadio {
  position: absolute;
  /* margin-right: 40px; */
  /* z-index: 99; */
  /* right: 1; */
  /* bottom: 20; */
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.rightRadio {
  position: absolute;
  right: 0;
  left: 1;
  z-index: 99;
}
.countryButton {
  padding: 8px, 16px, 8px, 16px;
  border-radius: 8px;
  border: 1px;
  gap: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.04em;
  margin-left: 20px;
  color: #e5c9ff;
  width: 124px;
  height: 44px;
  background: linear-gradient(0deg, #010101, #010101);
}

.operatorsCollage h6 {
  font-weight: 500;
  font-size: 14px;
  font-family: PJ-regular;
}
.operatorsCollage span {
  font-weight: 300;
  font-size: 14px;
  font-family: PJ-regular;
  line-height: 21px;
}

.accordion-step.active .accordion-content {
  display: block;
}
/* accordion ends  */
/* widget start */
.widgetButt {
  padding: 10px 12px 10px 10px;
  width: 250px;
  height: 44px;
  border: 1px #e7e7e7 solid;
  background-color: white;
  border-radius: 8px;
}
.widgetMain {
  display: flex;
}
.leftWidget {
  width: 70%;
}
.rightWidget {
  width: 30%;
}

.widgetInput {
  padding: 12px 10px 12px 10px;
  border-radius: 8px;
  border: 1px solid #b5b5b5;
  width: 425px;
}

/* toggle starts here  */
.toggle-container {
  width: 42px;
  height: 26px;
  background: #592c83;
  border-radius: 14px;
  position: relative;
  border: 1px solid #592c83;
  cursor: pointer;
}
.selectResult {
  display: inline-flex;
  gap: 6px;
  margin-right: 8px;
  height: 40px;
  color: black;
  font-size: 20px;
  border-radius: 8px;
  padding: 8px 4px 8px 4px;
  font-weight: 400;
  font-family: PJ-regular;
  margin-top: 12px;
}

.toggle {
  width: 20px;
  height: 21px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 4%;
  transition: transform 0.3s, background 0.3s;
}

.toggle.checked {
  transform: translateX(20px);
  background: white;
}

/* toggles ends here  */

/* config start here */

.configButton {
  background-color: #010101;
  padding: 16px 20px 16px 20px;
  color: #e5c9ff;
  height: 42px;
  border-radius: 10px;
  border: 1px solid;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  letter-spacing: -0.28px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.configDate {
  display: flex;
  height: 42px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid var(--Neutrals-neutrals-5, #d9d9d9);
  color: rgba(17, 17, 17, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
}

.dataConfigHeader h3 {
  font-family: PJ-regular;
  font-weight: 600;
  font-size: 28px;
}
.dataConfigHeader p {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

/* config ends here  */
/* empty state starts here */

/* empty state ends here */

/* `ticketing view area starts here */
.ticketing-view {
  display: flex;
  justify-content: end;
}
.ticketing-view p {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.ticketing-view p.active {
  background-color: #d6ddea;
  color: #6261c4;
}

/* `ticketing view area  ends here*/

/* dashboard starts here  */

.activityOverview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin-top: 58px; */
  /* margin-left: 40px; */
}
.activityOverview h3 {
  color: #101010;
  font-family: pj-regular;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.96px;
}
.exportButton {
  display: flex;
  height: 36px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid var(--Neutrals-neutrals-5, #d9d9d9);
  color: var(--Default-Body-Color, #181b01);
  text-align: center;
  font-feature-settings: "cv04" on, "cv01" on, "cv03" on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 20.3px */
}
.cardStyle {
  display: flex;
  justify-content: space-between;
}
.graphCard {
  width: 100%;
  padding: 32px;
  border-radius: 10px;
  border: 0.1px solid rgba(0, 0, 0, 0.1);
  background-color: #fefefe;
  /* box-shadow: 1px 2px 2px 2px rgba(50, 50, 50, 0.04); */
}
.graphCard h3 {
  color: #101010;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.88px;
}
.tableDash {
  width: 100%;
  padding: 32px;
  border-radius: 10px;
  background-color: #fefefe;
  border: 0.1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 2px 2px rgba(50, 50, 50, 0.04);
}
.tableDash th {
  color: #101010;
  font-family: PJ-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -1px;
  /* border: 1px solid black; */
}
.graphB {
  display: flex;
}
.dateBut {
  display: flex;
  height: 36px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid var(--Neutrals-neutrals-5, #d9d9d9);
  color: rgba(17, 17, 17, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
}
.graphB button {
  display: flex;
  width: 85px;
  height: 33px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-right: 1px solid #f1f1f1;
}
.upload {
  display: flex;
  width: 149px;
  height: 40px;
  padding: 8px 16px 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #462b60;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.56px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--Foundation-Primary-color-primary-color-100, #595959);
}
.changePass {
  color: var(--Secondary-secondary-700, #000e24);
  font-family: pj-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}
.profileCards {
  display: flex;
  justify-content: space-between;
}
.profileCarder {
  display: flex;
  justify-content: space-between;
  gap: 470px;
}
.regUser {
  display: flex;
  padding: 8px;
  margin-bottom: 26.5px;
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-start; */
  gap: 4px;
  border-radius: 5.333px;
  border: 0.667px solid rgba(30, 30, 30, 0.1);
}
.businesscard {
  display: flex;
  justify-content: space-between;
  gap: 100px;
}
.activityCard {
  display: flex;
  margin-top: 24px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  width: 265px;
  padding: 24px 8px;
  align-items: flex-end;
  align-content: center;
  border-radius: 13.34px;
  border: 0.4px solid rgba(169, 169, 169, 0.4);
  background: #fefefe;
  /* box-shadow: 2px 2px 2px 2px rgba(50, 50, 50, 0.02); */
}
.activityCard h4 {
  color: var(--Neutrals-neutrals-7, #8c8c8c);
  font-family: pj-regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.24px;
}
.activityCard h2 {
  color: #101010;
  font-family: pj-regular;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 31.2px */
  letter-spacing: -0.52px;
}
.percentage {
  display: flex;
  width: 65px;
  /* align-items: center; */
  height: 28px;
  justify-content: center;
  gap: 8px;
  border-radius: 25px;
  border: 2px solid #f1f1f1;
  color: green;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 12px */
  letter-spacing: -0.2px;
  padding-top: 5px;
}
.chartButton button {
  background-color: white;
  border: 1px solid #d9d9d9;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.chartButton button.active {
  background-color: #d9d9d9;
  color: black;
}
.chartButton :first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.chartButton :last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.percentDecrease {
  display: flex;
  width: 65px;
  /* align-items: center; */
  height: 28px;
  justify-content: center;
  gap: 8px;
  border-radius: 25px;
  border: 2px solid #f1f1f1;
  color: red;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 12px */
  letter-spacing: -0.2px;
  padding-top: 5px;
}

.activeTitle p {
  color: #101010;
  text-align: right;
  font-family: pj-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
}
.activeTitle h3 {
  color: #101010;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.8px;
}
.middleCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* table custom */

.table-container {
  overflow-x: auto;
  margin-bottom: 20px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.tableName {
  font-family: PJ-regular;
  color: #101010;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.56px;
}
.tableTime {
  color: #424242;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.48px;
}
.tableSource {
  color: #404040;
  font-family: PJ-regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;
}

/* .responsive-table th,
.responsive-table td {
  padding: 26px 20px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.responsive-table th:first-child,
.responsive-table td:first-child {
  border-top-left-radius: 8px;
  border: 1px solid;
}

.responsive-table th:last-child,
.responsive-table td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.responsive-table th:nth-child(1),
.responsive-table td:nth-child(1) {
  border-right: 1px solid #ddd;
}
.responsive-table th {
  font-family: PJ-regular;
} */

/* table custom ends */

/* Animationssssssssss starts here -------------------------- */
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Animationssssssssss ends here -------------------------- */

/* responsiveness starts here */

@media screen and (max-width: 2650px) {
  /* .messageCard {
    background-color: white;
    color: black;
    position: absolute;
    top: 390px;
    right: 212px;
    border-radius: 9px;
    box-shadow: 0px 0px 4px 0px rgb(193, 192, 192);
    display: flex;
    justify-content: center;
    padding: 9px 12px 12px 9px;
    width: 290px;
    display: flex;
    justify-content: space-between;
  } */
}
@media screen and (max-width: 1450px) {
  /* .messageCard {
    background-color: white;
    color: black;
    position: absolute;
    top: 390px;
    right: 62px;
    border-radius: 9px;
    box-shadow: 0px 0px 4px 0px rgb(193, 192, 192);
    display: flex;
    justify-content: center;
    padding: 9px 12px 12px 9px;
    width: 290px;
    display: flex;
    justify-content: space-between;
  } */
}
@media screen and (max-width: 1350px) {
  /* .messageCard {
    background-color: white;
    color: black;
    position: absolute;
    top: 420px;
    right: 20px;
    border-radius: 9px;
    box-shadow: 0px 0px 4px 0px rgb(193, 192, 192);
    display: flex;
    justify-content: center;
    padding: 9px 12px 12px 9px;
    width: 290px;
    display: flex;
    justify-content: space-between;
  } */
}
@media screen and (max-width: 1024px) {
  .pages .main-left-side {
    width: 100%;
    max-width: 100%;
  }
  /* .messageCard {
    background-color: white;
    color: black;
    position: absolute;
    top: 470px;
    right: 22px;
    border-radius: 9px;
    box-shadow: 0px 0px 4px 0px rgb(193, 192, 192);
    display: flex;
    justify-content: center;
    padding: 9px 12px 12px 9px;
    width: 290px;
    display: flex;
    justify-content: space-between;
  } */

  .pages .main-right-side {
    width: 100%;
    max-width: 100%;
  }

  .sidebar-area {
    height: 100%;
    /* position: relative; */
    /* position: fixed;
  z-index: 1;
  width: 80%;*/
  }

  .sidebar-area .header {
    /* width: 100%; */
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar-area .header .logo {
    padding: 0;
    margin: 0;
    color: #ffffff;
  }

  .sidebar-area .header span i {
    font-size: 20px;
    font-weight: 900;
  }

  .sidebar-area .navigation {
    animation: 0.07s slide-left ease-in-out;
  }

  .sidebar-area .navigation h6 {
    width: 90%;
    color: white;
  }

  .sidebar-area .navigation ul li div {
    padding-left: 10%;
    color: white;
  }
  .footer-nav-area .navigation {
    background-color: #fff;
    border: 1px solid #c7c7cd;
  }

  .footer-nav-area .navigation ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-nav-area .navigation ul li {
    text-decoration: none;
    list-style-type: none;
    color: #c7c7cd;
    padding: 0px 6px;
  }

  .footer-nav-area .navigation ul li:hover,
  .footer-nav-area .navigation ul li.active {
    color: #6261c4;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    border-top: 3px solid #6261c4;
  }

  .footer-nav-area .navigation ul li:hover span,
  .footer-nav-area .navigation ul li.active span {
    color: #6261c4;
  }

  .footer-nav-area .navigation ul li span {
    color: #6d767f;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 11px;
    margin: 3px 0px;
  }

  .footer-nav-area .navigation ul .link {
    text-decoration: none;
    list-style-type: none;
  }

  .footer-nav-area .navigation ul li i {
    margin: 0px 10px;
    font-size: 23px;
  }

  .sidebar-sm .sidebar-area {
    overflow-y: visible;
  }
  .btn-gray {
    background-color: #f1f1f1;
  }
  .user-profile {
    top: 120%;
    right: 0;
    background: #fff;
    padding: 6px 20px 6px 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    z-index: 50000;
  }
}

@media screen and (max-width: 900px) {
  .card-deck {
    display: "block";
  }
  .widgetMain {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .leftWidget {
    width: 100%;
  }
  .rightWidget {
    width: 100%;
  }
  /* .messageCard {
    background-color: white;
    color: black;
    position: absolute;
    top: 470px;
    right: 239px;
    border-radius: 9px;
    box-shadow: 0px 0px 4px 0px rgb(193, 192, 192);
    display: flex;
    justify-content: center;
    padding: 9px 12px 12px 9px;
    width: 290px;
    display: flex;
    justify-content: space-between;
  } */
}

@media screen and (max-width: 600px) {
  .sidebar-area .header {
    width: 92%;
    padding: 0;
  }
  .card-deck {
    display: "block";
  }
  .widgetMain {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .leftWidget {
    width: 100%;
  }
  .rightWidget {
    width: 100%;
  }
  .accordionContent {
    display: block;
  }
  /* .messageCard {
    background-color: white;
    color: black;
    position: absolute;
    top: 520px;
    right: 39px;
    border-radius: 9px;
    box-shadow: 0px 0px 4px 0px rgb(193, 192, 192);
    display: flex;
    justify-content: center;
    padding: 9px 12px 12px 9px;
    width: 290px;
    display: flex;
    justify-content: space-between;
  } */
}

/*  */
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-bg-type: rgba(0, 0, 0, 0.015);
}

.sub-menu {
  margin-left: 8px;
  position: relative;
  padding: 6px 0;
}

/* .sub-menu > .sub-menu-child {
  font-size: 12px;
  color: #ccc;
  padding: 10px 0;
  padding-left: 15px;
  margin: 0;
} */

/* 
.sub-menu > .sub-menu-child::before {
  content: "";
  position: absolute;
  left: 0;
  top: calc(50% - 9.5px);
  width: 3px;
  height: 20px;
  border-radius: 5%;
  background-color: #ccc;
}  */

.sub::before {
  content: "";
  position: absolute;
  /* left: 0; */
  top: calc(50% - 8px);
  left: calc(3% - 8px);
  width: 3px;
  height: 16px;
  border-radius: 8px;
  background-color: #8a94a6;
}

/* .sub-menu > .sub-menu-child::after {
  content: "";
  position: absolute;
  left: 0px;
  width: 1px;
  height: 110%;
  top: -15%;
  background-color: #ccc;
}  */

.inactive span {
  color: #bdbdbd;
}

/* custom logic for disabled buttons */
button[disabled] {
  opacity: 0.5;
  filter: grayscale(1);
}

.truncate-message {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 70%; /* Ensure the container has a fixed width */
  display: block; /* Make sure the element respects its width */
}

.skeleton-avatar,
.skeleton-small,
.skeleton-title,
.skeleton-tag {
  @apply bg-gray-300; /* Background color of the skeleton */
  @apply rounded; /* Rounded corners for better aesthetics */
  animation: loading 1.5s infinite ease-in-out; /* Loading animation */
}

.skeleton-avatar {
  @apply w-10 h-10; /* Size of the avatar */
}

.skeleton-small {
  @apply w-20 h-3; /* Size of the small text */
}

.skeleton-title {
  @apply w-32 h-6; /* Size of the title */
}

.skeleton-tag {
  @apply w-16 h-5; /* Size of the tag */
}

@keyframes loading {
  0% {
    opacity: 0.6;
  } /* Lower opacity at the start of animation */
  50% {
    opacity: 0.8;
  } /* Higher opacity in the middle */
  100% {
    opacity: 0.6;
  } /* Lower opacity at the end */
}
